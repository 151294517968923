import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import styled, { keyframes } from "styled-components";

import slugify from "slugify";
import distances from "src/assets/styles/distances.js";
import colors from "src/assets/styles/colors.js";
import main from "src/assets/styles/main.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import Line from "src/components/global/Line.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";

import News from "src/components/global/News/News.js";
import Button from "src/components/global/Button.js";
import Animate from "src/components/global/Typo/Animate.js";
import AnimateBody from "src/components/global/Typo/AnimateBody.js";
const StyledMainWrapper = styled(MainWrapper)`
  margin-top: ${(props) =>
    props.topPadding ? "0rem" : `${distances.sectionMarginBig}rem`};

  margin-bottom: ${distances.sectionMarginBig}rem;
  .bottomLine {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    .bottomLine {
      display: block;
    }
    margin-top: ${(props) =>
      props.topPadding ? "0rem" : `${distances.sectionMarginSmall}rem`};

    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;
const show = keyframes`
 0% { opacity: 0%; }
 100% { opacity: 100%; }
`;
const Title = styled.h2`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
  opacity: 0;
  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
`;

const Wraper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${distances.gap}rem;
`;

const DateWrapper = styled.div`
  width: 43.75%;
  /* background-color: red; */
  height: calc(33vw + 40rem);
  position: sticky;
  top: ${distances.gap + 60}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 20%;
    height: calc(16vw + 70rem);
  }
`;
const showBigDate = keyframes`
 0% { top: 17vw; }
 100% { top: 0vw; }
`;
const DateBig = styled.span`
  font-size: 20vw;
  line-height: 17vw;
  display: block;
  color: var(--rest);
  margin-top: 1vw;
  position: relative;
  top: 17vw;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  animation-name: ${showBigDate};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  &.bottom {
    transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 11vw;
    line-height: 9vw;
  }
`;

const DateTopRow = styled.div`
  height: 17vw;
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 9vw;
  }
`;
const DateBottomRow = styled.div`
  /* background-color: blue; */
  height: 17vw;
  overflow: hidden;
  ${DateBig} {
    animation-delay: 0.4s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 9vw;
  }
`;

const YearWrapper = styled.div`
  height: 33rem;
  /* background-color: red; */
  overflow: hidden;
  display: block;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 16rem;
    margin-top: 10rem;
  }
`;
const showYear = keyframes`
 0% { top: 40rem; }
 100% { top: 0; }
`;
const Year = styled.span`
  font-size: ${typographySizes.sm}rem;
  color: var(--rest);
  line-height: 33rem;
  display: block;
  transition: all 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;
  top: 40rem;
  animation-name: ${showYear};
  animation-duration: 0.5s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    line-height: 16rem;
  }
`;

const NewsWrapper = styled.div`
  width: 56.25%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 80%;
  }
`;
const BottomRow = styled.div`
  margin-left: 0rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 150rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 100rem;
  }
`;

const ButtonWrapper = styled.a`
  border: solid 1rem;
  border-color: var(--rest);
  font-size: ${typographySizes.m}rem;
  text-align: center;
  color: var(--rest);
  align-self: center;
  display: inline-block;
  padding: 0 30rem;

  cursor: pointer;
  /* margin-left: 50rem; */
  padding: 0 30rem;

  border-radius: 60rem;
  height: 51rem;
  overflow: hidden;
  text-decoration: none;
  span {
    font-size: inherit;
    color: inherit;
    display: block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    text-decoration: none;
  }
  :hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  &.active {
    background: var(--rest);
    color: var(--bg);
    border: solid 1rem var(--bg);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    padding: 5rem 15rem;

    height: 18rem;
  }
`;

const NewsAll = ({ news, allButtonText, lang, pageName, topPadding }) => {
  const [curPost, setCurPost] = useState(0);
  const [dateMove, setDateMove] = useState(18);
  const [yearMove, setYearMove] = useState(33);
  const ulRef = useRef(null);

  const isBrowser = typeof window !== "undefined";
  let intViewportWidth = 0;
  // let dateMove = 18;
  const langUrl = lang == "pl" ? "" : `/${lang}`;
  const aboutUrl = pageName
    ? `${langUrl}/${slugify(pageName, {
        lower: true,
        strict: true,
      })}`
    : "";

  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }
  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("scroll", () => {
        for (var i = 0; i < news.edges.length; i++) {
          if (isBrowser && intViewportWidth < 768) {
            if (
              ulRef.current.children[i].getBoundingClientRect().top < 90 &&
              ulRef.current.children[i].getBoundingClientRect().top > -10
            ) {
              setCurPost(i);
            }
          } else {
            if (
              ulRef.current.children[i].getBoundingClientRect().top < 30 &&
              ulRef.current.children[i].getBoundingClientRect().top > -50
            ) {
              setCurPost(i);
            }
          }
        }
      });

      if (isBrowser && intViewportWidth < 768) {
        setDateMove(10);
        setYearMove(16);
        console.log("testsize");
      }
    }
  }, []);

  return (
    <StyledMainWrapper topPadding={topPadding}>
      <Title>news</Title>
      <Line className={"animate"} />
      <Wraper>
        <DateWrapper>
          <DateTopRow>
            {news.edges.map((newsPos) => (
              <DateBig
                style={{
                  transform: "translateY(" + curPost * -dateMove + "vw)",
                }}
              >
                {newsPos.node.Date.split(".")[0]}
              </DateBig>
            ))}
          </DateTopRow>
          <DateBottomRow>
            {news.edges.map((newsPos) => (
              <DateBig
                className="bottom"
                style={{
                  transform: "translateY(" + curPost * -dateMove + "vw)",
                }}
              >
                {newsPos.node.Date.split(".")[1]}
              </DateBig>
            ))}
          </DateBottomRow>
          <YearWrapper>
            {news.edges.map((newsPos) => (
              <Year
                style={{
                  transform: "translateY(" + curPost * -yearMove + "rem)",
                }}
              >
                (2K{newsPos.node.Date.split(".")[2].slice(-2)})
              </Year>
            ))}
          </YearWrapper>
        </DateWrapper>

        <NewsWrapper ref={ulRef}>
          {news.edges.map((newsPos, index) => (
            <News langUrl={langUrl} newsPos={newsPos} index={index} />
          ))}
        </NewsWrapper>
      </Wraper>
      <Line className="bottomLine" />
      {allButtonText && (
        <BottomRow>
          <ButtonWrapper href={aboutUrl}>
            <span>{allButtonText}</span>
            <span>{allButtonText}</span>
          </ButtonWrapper>
        </BottomRow>
      )}
    </StyledMainWrapper>
  );
};

export default NewsAll;
