import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Seo from "src/components/global/Seo.js";

import Nav from "src/components/global/Nav.js";
import NewsAll from "src/components/global/News/NewsAll.js";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import Footer from "src/components/global/Footer.js";
// markup

const Wrapper = styled.div`
  margin-bottom: 50rem;
  padding-top: ${distances.topMargin}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${distances.topMarginMobile}rem;
    margin-bottom: 15rem;
  }
`;

const News = ({ data, pageContext }) => {
  return (
    <Wrapper>
      <Seo
        title={data.strapiPageNews.Seo && data.strapiPageNews.Seo.Title}
        description={
          data.strapiPageNews.Seo && data.strapiPageNews.Seo.Description
        }
        image={
          data.strapiPageNews.Seo &&
          data.strapiPageNews.Seo.Img.localFile.publicURL
        }
      />
      <Nav
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
      />
      <NewsAll
        topPadding={distances.topMargin}
        news={data.allStrapiPost}
        lang={pageContext.locale}
      />
      <Footer
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        lang={pageContext.locale}
      />
    </Wrapper>
  );
};

export default News;

export const IndexQuery = graphql`
  query News($locale: String!) {
    allStrapiPost(
      sort: { order: DESC, fields: Date }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Date(formatString: "DD.MM.YYYY")
          Title
          Thumbnail {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
        }
      }
    }
    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageNews(locale: { eq: $locale }) {
      Seo {
        Title
        Description
        Img {
          localFile {
            publicURL
          }
        }
      }
      Page_name
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
