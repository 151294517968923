import styled from "styled-components";
import { Link } from "gatsby";
import typographySizes from "src/assets/styles/typographySizes.js";

const Button = styled(Link)`
  font-size: ${typographySizes.s}rem;
  line-height: ${typographySizes.sh};
  position: relative;
  color: ${(props) => (props.color ? props.color : "var(--rest)")};
  bottom: 5rem;
  display: inline;

  text-decoration: none;

  cursor: pointer;
  ::after {
    content: "";
    right: 0;
    bottom: -5rem;
    height: 1rem;
    width: 100%;
    position: absolute;
    background-color: ${(props) => (props.color ? props.color : "var(--rest)")};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ::before {
    content: "";
    left: 0;
    bottom: -5rem;
    height: 1rem;
    width: 00%;
    position: absolute;
    background-color: ${(props) => (props.color ? props.color : "var(--rest)")};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    ::after {
      width: 0;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ::before {
      width: 100%;
      transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

export default Button;
