import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const SmallTitle = styled.h2`
  font-size: ${typographySizes.l}rem;
  line-height: ${typographySizes.lh};
  color: var(--rest);
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.sm}rem;
  }
`;

export default SmallTitle;
