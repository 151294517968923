import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import styled, { keyframes } from "styled-components";

import slugify from "slugify";
import distances from "src/assets/styles/distances.js";
import colors from "src/assets/styles/colors.js";
import main from "src/assets/styles/main.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import Line from "src/components/global/Line.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";
import Body from "src/components/global/Typo/Body.js";
import ButtonArrow from "src/components/global/ButtonArrow.js";

const Title = styled.h3`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: var(--rest);
  width: 50%;
  align-self: start;
  margin-bottom: ${distances.gap}rem;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.sm}rem;
    line-height: ${typographySizes.smh};
    margin-bottom: ${distances.gap * 2}rem;
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  height: 100%;
`;
const show = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;
const NewsWrapperPos = styled(Link)`
  width: 100%;
  margin-bottom: 30rem;
  display: inline-block;
  position: relative;
  animation-name: ${show};
  opacity: 0;
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  &:last-of-type {
    margin-bottom: 0rem;
  }
  :hover {
    ${StyledImg} {
      transform: scale(1.02);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    &:last-of-type {
      hr {
        display: none;
      }
    }
  }
`;

const ImgWrapper = styled.div`
  overflow: hidden;
  border-radius: ${main.radius}rem;
  /* height: 30.5vw; */
  /* @media (max-width: ${mediaQuery.tablet}) {
    height: 50vw;
  } */
`;

const BottomWrapper = styled.div`
  margin-top: ${distances.gap}rem;
  margin-bottom: ${distances.gap}rem;
  display: flex;

  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${mediaQuery.laptop}) {
    /* justify-content: flex-end; */
  }
  @media (max-width: ${mediaQuery.tablet}) {
    align-items: flex-start;
  }
`;

const News = (props) => {
  const [boxHover, setBoxHover] = useState(false);
  const hoverOn = (e) => {
    setBoxHover(true);
  };
  const hoverOff = (e) => {
    setBoxHover(false);
  };
  let slugTitle = slugify(props.newsPos.node.Title, {
    lower: true,
    strict: true,
  });
  const postUrl = props.langUrl + "/news/" + slugTitle;
  return (
    <NewsWrapperPos onMouseEnter={hoverOn} onMouseLeave={hoverOff} to={postUrl}>
      <ImgWrapper>
        <StyledImg
          loading="eager"
          fluid={props.newsPos.node.Thumbnail.localFile.childImageSharp.fluid}
        />
      </ImgWrapper>

      <BottomWrapper>
        <Title>{props.newsPos.node.Title}</Title>
        <ButtonArrow link={postUrl} hover={boxHover}>
          zobacz więcej
        </ButtonArrow>
      </BottomWrapper>
      <Line />
    </NewsWrapperPos>
  );
};

export default News;
